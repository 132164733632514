const Routes = {
	Main: '/',
	// auth routes
	Login: '/login',

	// onboarding routes
	Onboarding: '/onboarding',
	OnboardingCourseKey: '/onboarding/:coursekey',
	OnboardingCourses: '/onboarding/courses',
	Signup: '/onboarding/signup',
	OnboardingAspirations: '/onboarding/:coursekey/aspirations',
	OnboardingBackground: '/onboarding/:coursekey/stage',
	OnboardingSpecialization: '/onboarding/:coursekey/specializations',
	OnboardingProfileDetails: '/onboarding/profiledetails',
	// OnboardingStepCompletion: '/onboarding/completion',

	// dashboard routes
	Dashboard: '/dashboard',
	DashboardCourses: '/dashboard/courses/:fromlevel?',
	CoursesBasePath: '/dashboard/courses',
	DashboardLevel: '/dashboard/courses/:phase/:modulenumber/:module/:levelnumber/level',

	// jobs
	Jobs: '/jobs',

	//BookMarks
	Bookmarks: '/bookmarks',

	// TEST ROUTES
	Profile: '/profile',
	BasicInfo: '/profile/basic-info',
	ProfessionalDetails: '/profile/professional-details',
	EducationDetails: '/profile/academic-details',
	AccountDetails: '/profile/account-details',
	CourseActivity: '/profile/activity',
	WorkPreference: '/profile/work-preference',
	ProfileCV: '/profile/create-cv',

	// pricing page
	Pricing: '/pricing',
	Link: '/payment',

	//Landing Pages
	LandingPages: {
		BestFreeDigitalMarketingCourse: '/best-free-digital-marketing-course',
		DigitalMarketingCourse: '/digital-marketing-course',
		BestDigitalMarketingCourse: '/best-digital-marketing-course',
		DigitalMarketing: '/digitalmarketing',
		DMCrashCourseV1: '/best-digital-marketing-crash-course',
		DMCrashCourseV2: '/digital-marketing-crash-course',
	},

	// Optout
	OptOut: '/opt-out',
	PostSignUpLandingPage: '/postsignup',

};

export default Routes;
