import {
    SET_COURSE_DATA, SET_COURSE_CURRENT_STATUS, USER_ACTIVITY_DETAILS, SET_LEVEL_DATA,
    SET_BOOKMARKS, SET_BLOCKED_CONTENTS, PRICING_PAGE, GRAPH_DATA, SET_DAYWEEKPLAN, SET_COURSE_COMPLETED,
    FAIL_QUESTIONS, NOTIFICATIONS, SET_UTM_COURSE, SET_QUIZ_ATTEMPTED_FLAG, SET_DAYWEEKPLAN_TOGGLE
} from '../types'
import { PURGE } from 'redux-persist'

export default (state = null, action) => {
    switch (action.type) {
        case PURGE:
            return null

        case SET_COURSE_DATA:
            return {
                ...state,
                contents: action.payload.path,
                gamification: {
                    ...state.gamification,
                    ...action.payload.gamification
                },
                locking: action.payload.locking,
                bookmarks: action.payload.bookmarks,
                subscription: action.payload.subscription,
                dayWeekPlan: action.payload.dayWeekPlan,
                discount: action.payload.discount,
                lp_levels: action.payload.lp_levels
            }

        case SET_LEVEL_DATA:
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.payload.phase]: action.payload.data
                }
            }

        case USER_ACTIVITY_DETAILS:
            let n = 0
            if (action.payload.notifications) {
                action.payload.notifications.map((notification) => {
                    if (notification.read === false)
                        n++
                })
                return {
                    ...state,
                    gamification: {
                        points: action.payload.point,
                        streaks: action.payload.streaks,
                        averageActivity: action.payload.average_activity
                    },
                    notifications: action.payload.notifications.reverse(),
                    not_read: n
                }
            } else {
                return {
                    ...state,
                    gamification: {
                        points: action.payload.point,
                        streaks: action.payload.streaks,
                        averageActivity: action.payload.average_activity
                    },
                    not_read: n
                }
            }

        case SET_UTM_COURSE:
            return {
                ...state,
                utm: action.payload.utm
            }

        case FAIL_QUESTIONS:
            // let k = 0
            // if (action.payload.notifications) {
            //     action.payload.notifications.map((notification) => {
            //         if (notification.read === false)
            //             k++
            //     })
            //     return {
            //         ...state,
            //         failedQuestions: action.payload.failed_questions,
            //         gamification: {
            //             ...state.gamification,
            //             ...action.payload.gamification
            //         },
            //         notifications: action.payload.notifications.reverse(),
            //         not_read: k
            //     }
            // } else {
            return {
                ...state,
                failed_questions: action.payload.failed_questions
            }
        // }

        case NOTIFICATIONS:
            let i = 0
            action.payload.map((notification) => {
                if (notification.read === false)
                    i++
            })
            return {
                ...state,
                notifications: action.payload.reverse(),
                not_read: i
            }

        case SET_COURSE_CURRENT_STATUS:
            return {
                ...state,
                current_status: action.payload
            }

        case GRAPH_DATA:
            const data = [0, 0, 0, 0, 0, 0, 0]
            action.payload.forEach(el => {
                data[el.dayOfWeek - 1] = el.time
            })
            return {
                ...state,
                graphData: data
            }

        case SET_BOOKMARKS:
            return {
                ...state,
                bookmarks: action.payload
            }
        case PRICING_PAGE:
            return {
                ...state,
                pricing: action.payload
            }
        case SET_BLOCKED_CONTENTS:
            let path = state.contents
            path[action.payload.phase][action.payload.module][action.payload.level]["blocked"] = action.payload.blocked
            return {
                ...state,
                contents: path
            }
        case SET_DAYWEEKPLAN:
            return {
                ...state,
                dayWeekPlan: { ...state.dayWeekPlan, ...action.payload }
            }
        case SET_DAYWEEKPLAN_TOGGLE:
            return {
                ...state,
                dayWeekPlan: {
                    ...state.dayWeekPlan,
                    enabled: action.payload
                }
            }
        case SET_COURSE_COMPLETED:
            return {
                ...state,
                course_completed: action.payload
            }
        case SET_QUIZ_ATTEMPTED_FLAG:
            return {
                ...state,
                quiz_attempted: action.payload
            }
        default:
            return state;
    }
};
