import React from 'react';
import Icon from '../utils/icon';
import '../styles/AdBlockModalContent.scss';

const AdBlockModalContent = ({ hadleAdBlockModalDissmised }) => {

	return (
		<div className="container adblock-bg">
			<div className="row justify-content-center">
				<div className="col-12">
					<div className="row justify-content-center pt-4">
						<Icon icon="ICON_AD_BLOCK" className="col-8 adbloc-icon" />
					</div>
					<h1 className="adblock-heading"> Ad Blocker Detected!</h1>
					<div className="row adblock-text justify-content-center pb-4">
						<div className="col-12 pb-4">
							We have noticed that you are using an Ad blocker.
						<br />
						We request you to turn it off.
						<br />
						Please be aware that our site is best experienced with Ad blockers turned
						off.
					</div>
						<button type="button" class="btn adblock-btn" onClick={hadleAdBlockModalDissmised}>
							I have disabled Ad blocking for this site
					</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AdBlockModalContent;
