require('dotenv').config();
export const ENV = process.env.REACT_APP_ENV
export const REK = process.env.REACT_APP_REK
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID
export const FB_LOGIN_URL = process.env.REACT_APP_FB_LOGIN_URL
export const GEOLOCATION_URL = process.env.REACT_APP_GEOLOCATION_URL
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY

let API_AUTH_URL, API_ONBOARDING_URL, API_PROFILE_URL, API_CONTENT_URL, API_PATHWAY_URL, API_ACTIVITY_URL, API_PAYMENT_LINK, API_QUIZ_URL, API_JOBS_URL, API_COMMUNITY_URL, GOOGLE_CLIENT_ID, REACT_APP_QUIZ_URL, API_LOGS_URL, REACT_APP_COMMUNITY_URL, API_NOTIFICATION_LINK, FB_REDIRECT_URL

if (ENV === "LOCAL_DEVELOPMENT") {
    // LOCAL DEVELOPMENT
    API_AUTH_URL = process.env.REACT_APP_AUTH_URL
    API_ONBOARDING_URL = process.env.REACT_APP_ONBOARDING_URL
    API_PROFILE_URL = process.env.REACT_APP_PROFILE_URL
    API_CONTENT_URL = process.env.REACT_APP_CONTENT_URL
    API_PATHWAY_URL = process.env.REACT_APP_PATHWAY_URL
    API_ACTIVITY_URL = process.env.REACT_APP_ACTIVITY_URL
    API_QUIZ_URL = process.env.REACT_APP_QUIZ_URL
    API_JOBS_URL = process.env.REACT_APP_JOBS_URL
    API_NOTIFICATION_LINK = process.env.REACT_APP_NOTIFICATION_URL
    REACT_APP_COMMUNITY_URL = process.env.REACT_APP_COMMUNITY_DEV_URL
    API_COMMUNITY_URL = process.env.REACT_APP_COMMUNITY_DEV_URL
    GOOGLE_CLIENT_ID = process.env.REACT_APP_DEV_GOOGLE_CLIENT_ID
    REACT_APP_QUIZ_URL = process.env.REACT_APP_QUIZ_DEV_REDIRECT_URL
    API_LOGS_URL = process.env.REACT_APP_LOGS_URL
    API_PAYMENT_LINK = process.env.REACT_APP_PAYMENT_LINK_URL
    FB_REDIRECT_URL = process.env.REACT_APP_FB_REDIRECT_DEV_URL
} else {
    // PRODUCTION/ STAGING/ DEVELOPMENT
    API_AUTH_URL = `${process.env.REACT_APP_BASE_URL}/authentication`
    API_ONBOARDING_URL = `${process.env.REACT_APP_BASE_URL}/onboarding`
    API_PROFILE_URL = `${process.env.REACT_APP_BASE_URL}/user`
    API_CONTENT_URL = `${process.env.REACT_APP_BASE_URL}/content`
    API_PATHWAY_URL = `${process.env.REACT_APP_BASE_URL}/pathway`
    API_ACTIVITY_URL = `${process.env.REACT_APP_BASE_URL}/activity`
    API_QUIZ_URL = `${process.env.REACT_APP_BASE_URL}/quiz`
    API_JOBS_URL = `${process.env.REACT_APP_BASE_URL}/jobs`
    API_LOGS_URL = `${process.env.REACT_APP_BASE_URL}/logs`
    API_PAYMENT_LINK = `${process.env.REACT_APP_BASE_URL}/payment`
    API_NOTIFICATION_LINK = `${process.env.REACT_APP_BASE_URL}/notifications`
    REACT_APP_COMMUNITY_URL = process.env.REACT_APP_COMMUNITY_URL
    API_COMMUNITY_URL = `${process.env.REACT_APP_COMMUNITY_URL}/middleware`
    GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
    REACT_APP_QUIZ_URL = process.env.REACT_APP_QUIZ_REDIRECT_URL
    API_PAYMENT_LINK = process.env.REACT_APP_PAYMENT_LINK_URL
    FB_REDIRECT_URL = process.env.REACT_APP_FB_REDIRECT_URL
}

export {
    API_AUTH_URL, API_ONBOARDING_URL, API_PROFILE_URL, API_CONTENT_URL, API_PATHWAY_URL, API_ACTIVITY_URL, API_QUIZ_URL,
    API_JOBS_URL, API_COMMUNITY_URL, GOOGLE_CLIENT_ID, REACT_APP_QUIZ_URL, API_LOGS_URL, REACT_APP_COMMUNITY_URL, API_PAYMENT_LINK, API_NOTIFICATION_LINK, FB_REDIRECT_URL
}
