import React, { lazy, Suspense, useEffect, useState } from 'react';
import 'styles/index.scss';
// redux
import { createStore, applyMiddleware } from 'redux';
// import logger from 'redux-logger'
import reduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { axiosAuthMiddleware } from 'middleware/axios-middleware';
import reducers from 'redux/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { encryptTransform } from 'redux-persist-transform-encrypt'
// routes and routing
import Routes from 'config/routes';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// scroll to top
import ScrollToTop from 'utils/scroll-to-top';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Error Boundary
import ErrorBoundary from 'utils/error-boundary';
// Views
import Loader from './Loader';
import { REK, ENV } from '../config/config'
import { retry } from '../helper/helper'

import { Mixpanel } from '../config/Mixpanel';
import getOS from '../config/getOS';
import LinkPage from '../views/linkpage/LinkPage'
// import LogRocket from 'logrocket';
import Constants from '../config/constants';
import ReactPixel from 'react-facebook-pixel';
import { detectAnyAdblocker } from 'just-detect-adblock';
import { Modal } from 'reactstrap';
import AdBlockModalContent from '../common-components/AdBlockModalContent';


String.prototype.toProperCase = function () {
	return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: true, // enable logs
};

ReactPixel.init('333999584825866', options);
ReactPixel.init('2001403463411336', options);


//LogRocket.init('9bmh4l/cn-frontend');

const encryptor = encryptTransform({
	secretKey: REK,
	onError: function (error) {
		// Handle the error.
		console.log(error)
	}
})

const persistConfig = {
	key: 'v2',
	transforms: [encryptor],
	storage: storage,
	stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
	// blacklist: ['career_goal'],
};

const pReducer = persistReducer(persistConfig, reducers);

let createStoreWithMiddleware = null
if (ENV === Constants.environment.dev) {
	createStoreWithMiddleware = applyMiddleware(
		reduxThunk,
		// logger,
		axiosAuthMiddleware
	)(createStore);
} else {
	createStoreWithMiddleware = applyMiddleware(
		reduxThunk,
		// LogRocket.reduxMiddleware(), //LOGROCKET MIDDLEWARE
		axiosAuthMiddleware
	)(createStore);
}

export const store = (ENV === Constants.environment.dev || ENV === Constants.environment.localdev) ? createStoreWithMiddleware(
	pReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
) : createStoreWithMiddleware(
	pReducer
)

const persistor = persistStore(store);

const Login = lazy(() => {
	return retry(() => import('views/authentication/Login'));
});
const Onboarding = lazy(() => {
	return retry(() => import('views/onboarding/Onboarding'));
});
const LandingPage = lazy(() => {
	return retry(() => import('views/landingpage/LandingPage'));
});
const Main = lazy(() => {
	return retry(() => import('./Main'));
});

const PricingPage = lazy(() => {
	return retry(() => import('../views/pricing/PricingPage'));
});

const OptoutPage = lazy(() => {
	return retry(() => import('views/optout/Index'));
});

const App = (props) => {
	const [showAdBlockModal, setShowAdBlockModal] = useState(false);
	const [showAdBlockModalDissmised, setShowAdBlockModalDissmised] = useState(false);

	useEffect(() => {
		const storeEventPWA = (e) => {
			// Prevent the mini-infobar from appearing on mobile
			e.preventDefault();
			// Stash the event so it can be triggered later.
			window.deferredPrompt = e
			// Update UI notify the user they can install the PWA
			// showInstallPromotion();
			window.removeEventListener('beforeinstallprompt', storeEventPWA)
		}
		window.addEventListener('beforeinstallprompt', storeEventPWA)
	}, []);

	useEffect(() => {
		detectAnyAdblocker().then(detected => {
			if (detected && !showAdBlockModalDissmised) {
				// an adblocker is detected
				setShowAdBlockModal(true);
			}
		});
	});

	const hadleAdBlockModalDissmised = () => {
		setShowAdBlockModal(false);
		setShowAdBlockModalDissmised(true);
	}

	return (
		<Provider store={store}>
			<PersistGate loading persistor={persistor}>
				<ErrorBoundary>
					<Router>
						<ScrollToTop>
							<Suspense fallback={<Loader />}>
								<Route path={Routes.Login} component={Login} />
								<Route exact path={Routes.Main} component={Login} />
								<Route path={Routes.Onboarding} component={Onboarding} />
								<Route path={[Routes.Dashboard, Routes.Profile, Routes.Jobs, Routes.Bookmarks, Routes.PostSignUpLandingPage]} component={Main} />

								<Route path={[Routes.LandingPages.DigitalMarketingCourse, Routes.LandingPages.BestDigitalMarketingCourse, Routes.LandingPages.DigitalMarketing]} component={LandingPage} />
								{/* <Route path={Routes.LandingPages.DMCrashCourseV1} component={LandingPage} />
								<Route path={Routes.LandingPages.DMCrashCourseV2} component={LandingPage} /> */}

								<Route path={Routes.Link} component={LinkPage} />
								<Route path={Routes.Pricing} component={PricingPage} />
								<Route path={Routes.OptOut} component={OptoutPage} />
							</Suspense>
						</ScrollToTop>
					</Router>

					<ToastContainer />
					<Modal
						isOpen={showAdBlockModal}
						centered
						keyboard={false}
						toggle={hadleAdBlockModalDissmised}
					>
						<AdBlockModalContent hadleAdBlockModalDissmised={hadleAdBlockModalDissmised} />
					</Modal>
				</ErrorBoundary>
			</PersistGate>
		</Provider>
	);
}

class AppWrapper extends React.Component {

	constructor(props) {
		super(props);
		this.currentOS = getOS();
	}

	mixpanelSessionEndEventMobile = () => {
		if (document.visibilityState === 'hidden') {
			Mixpanel.set_config({ api_transport: 'sendBeacon' });
			Mixpanel.track(Constants.MXP.SESSION_END, {
				distinct_id: store?.user?.profile?.email
			})
		} else {
		}
	}

	mixpanelSessionEndEventDesktop = () => {
		Mixpanel.set_config({ api_transport: 'sendBeacon' });
		Mixpanel.track(Constants.MXP.SESSION_END, {
			distinct_id: store?.user?.profile?.email
		})
	}

	componentDidMount() {
		// alert(this.currentOS);
		if (this.currentOS === ('Android' || 'iOS')) {
			document.addEventListener('visibilitychange', this.mixpanelSessionEndEventMobile)
		} else {
			window.addEventListener('beforeunload', this.mixpanelSessionEndEventDesktop)
		}
	}

	componentWillUnmount() {
		if (this.currentOS === ('Android' || 'iOS')) {
			document.removeEventListener('visibilitychange', this.mixpanelSessionEndEventMobile)
		} else {
			window.removeEventListener('beforeunload', this.mixpanelSessionEndEventDesktop)
		}
	}
	render() {
		return (
			<App props={this.props} />
		)
	}
}

export default AppWrapper